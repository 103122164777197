import logo from './assets/TECNICOLORES.gif';
import banner from './assets/CompliandoBanner.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Una comunidad de techies LBGTQIA+ en México
        </p>
       <EventsSection/>
      </header>
    </div>
  );
}

export default App;


function EventsSection(){
  return(
    <section>
      <h2>Próximo Evento</h2>
      <img className = "banner-image" src={banner} alt="banner for june 20th event: Compliando Conexiones" />
      <h3>Compliando Conexiones</h3>
      <p>Evento para conocer a otras personas de la comunidad y hacer networking.</p>
      <p>20 de junio 2024</p>
      <p>19:00 - 22:00</p>
      <p>Enigma Less Club</p>
      <p>General Prim 9, Colonia Centro CDMX</p>
      <p>Entrada libre</p>
      <a href="https://calendar.app.google/dJKfxtV5EeyfhuRg9">Agregar a calendario</a>
      <span><p>Favor de realizar tu RSVP <a href="https://forms.gle/aiVv6PxC8S8DduBd6">aquí</a>.</p></span>
      <br/>
      <br/>
      <a href="https://www.facebook.com/share/eGx6taPpdhGRhqyy/?mibextid=JRoKGi">Síguenos en Facebook</a><br/>
      <a href="https://www.instagram.com/tecnicolores.mx">Síguenos en Instagram</a><br/>
      <a href="hhttps://www.linkedin.com/groups/14371546/">Síguenos en LinkedIn</a>
    </section>
  )
}